import React from 'react'

export default function Intro() {
    return (
        <div className="wrap py-16">
            <div className="img-wrap">
                <img src="/tuso.jpeg" className='w-[100px] mx-auto' alt="Atsuko" />
            </div>

            <div className="socials flex gap-5 justify-center items-center font-light uppercase text-lg mt-5">
                <a href="#" className='underline'>Socials:</a>
                <a href="https://t.me/atsukosatoctoportal">Telegram</a>
                <a href="https://x.com/ATSUKOSATO_CTO">X (formely twitter)</a>
              
            </div>

            <div className="wrap mt-16 text-center p-5">
                <p className='uppercase text-xl'>ATSUKO SATO CTO - $SATO</p>
                <h1 className='lg:text-8xl text-4xl font-extrabold my-5 mt-3 font-jumb'>MEet $ATSUKO</h1>
                <span className='italic text-3xl'>$Meet Atsuko Sato, the crypto #DOGEMOTHER behind $DOGE, $NEIRO, $GINAN, and $TSUJI.  </span>
            </div>

            <div className="btn-wrap text-center my-10 ">
            <a href ="https://www.dextools.io/app/en/ether/pair-explorer/0x2f995d34e930b1a37a507ab8016c40270789a6d4?t=1722444675108" className='p-4 px-7 border border-black font-jumb text-red-800'>DexTools</a>
            </div>

            <div className="wrap py-5 my-5">
                <div className="flex flex-wrap gap-5 justify-center">
                    <img src="/tuso.jpeg" alt="Atsuko" className='w-[400px]' />
                    <img src="/aks.jpeg" alt="Atsuko" className='w-[400px]' />
                    <img src="/akss.jpeg" alt="Atsuko" className='w-[400px]' />
                    <img src="/ak.jpeg" alt="Atsuko" className='w-[400px]' />
                </div>
            </div>
        </div>
    )
}
