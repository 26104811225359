import React from 'react'

export default function Footer() {
    return (
        <div class="bg-black py-16 text-lg text-gray-300">
            <div className="container mx-auto">
                <p>COPYRIGHT © 2024 CUTE <span className="font-jumb text-amber-800">
                    $Atusko</span> - ALL RIGHTS RESERVED.</p> <br />
                
            </div>
        </div>

    )
}
