import React from 'react'

export default function Links() {
    return (
        <div className="wrap bg-black bg-opacity-20 py-16">
            <div className="container mx-auto">
                <div className="header">
                    <h1 className='text-4xl font-bold  text-amber-800'>Links</h1>
                </div>

                <div className="flex flex-wrap gap-10 justify-center md:w-1/2 mx-auto font-jumb mt-3">
                    <a href="https://t.me/atsukosatoctoportal">Telegram</a>
                    <a href="https://x.com/ATSUKOSATO_CTO">X</a>
                    
                    <a href="https://www.dexscreener.com/ethereum/0x2F995D34E930B1A37A507AB8016c40270789A6d4">DexScreener</a>
                    <a href ="https://www.dextools.io/app/en/ether/pair-explorer/0x2f995d34e930b1a37a507ab8016c40270789a6d4?t=1722444675108" className='p-4 px-7 border border-black font-jumb text-red-800'>DexTools</a>
                    

                </div>
            </div>
        </div>
    )
}
